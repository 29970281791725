.rangeslider {
  margin: 0;
  background-color: #ebedee;
  cursor: pointer;
}

.rangeslider-horizontal {
  height: 8px;
}
.range-slider-disabled {
  pointer-events: none;
}

.range-slider-disabled.rangeslider-horizontal .rangeslider__fill {
  background-color: #adb3b8;
}

.range-slider-disabled.rangeslider-horizontal .rangeslider__handle:after {
  background-color: #adb3b8;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #5f259f;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 24px;
  height: 24px;
}

.rangeslider .rangeslider__handle {
  border: 2px solid #fafafa;
  box-sizing: border-box;
  box-shadow: 0px 2px 16px rgba(47, 57, 65, 0.16);
}
.rangeslider .rangeslider__handle:active,
.rangeslider .rangeslider__handle:hover,
.rangeslider .rangeslider__handle:focus {
  box-shadow: #dbc9e9ab 0px 0px 0px 10px;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  box-shadow: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  width: 20px;
  height: 20px;
  background-color: #5f259f;
  top: 0;
  left: 0;
  box-shadow: none;
}

.rangeslider .rangeslider__handle-tooltip {
  width: 110px;
  height: 32px;
  background: #2f3941;
  border-radius: 4px;
  box-shadow: 0px 1px 5px rgba(4, 11, 23, 0.04),
    0px 5px 25px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.09);
}

.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 8px solid #2f3941;
}

.rangeslider .rangeslider__handle-tooltip span {
  margin: 8px;
}
