.amplify-tabs {
  display: none;
}
.amplify-button--link {
  display: none;
}

:root,
[data-amplify-theme] {
  --amplify-components-authenticator-router-border-style: none;
  --amplify-components-authenticator-form-padding: var(--amplify-space-zero);
  --amplify-components-button-primary-background-color: #fcaf17;
  --amplify-components-button-primary-color: #2f3941;
}

[data-amplify-authenticator] [data-amplify-router] {
  padding: 0 38px !important;
  border-radius: 0;
  box-shadow: none;
}

.amplify-button--primary {
  border-radius: 24px;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  padding: 14px;
  font-weight: bold !important;
  border-width: var(--amplify-components-button-primary-border-width);
  background-color: var(--amplify-components-button-primary-background-color);
  border-color: var(--amplify-components-button-primary-border-color);
  color: var(--amplify-components-button-primary-color);
}

.amplify-button--primary:hover,
.amplify-button--primary:focus {
  background-color: #fdc630;
  color: var(--amplify-components-button-primary-color);
}

fieldset.amplify-flex, form > .amplify-flex {
  gap: 24px;
}

.amplify-button--disabled{
  pointer-events: none;
  background: #EBEDEE;
  color: #747E85;
}

.amplify-input {
  border-color: #cecece;
  box-shadow: none;
}

.amplify-loader {
  display: none;
}

.amplify-field-group__outer-end .amplify-field-group__control:not(:focus) {
  border-color: #cecece;
  border-left: none;
}
.amplify-field-group__outer-end .amplify-field-group__control:focus {
  border-color: var(--amplify-components-fieldcontrol-focus-border-color);
  box-shadow: none;
}
.amplify-input:focus {
  border-color: var(--amplify-components-fieldcontrol-focus-border-color);
  box-shadow: none;
}

.amplify-divider--label::after {
  content: "ou";
}

[data-amplify-authenticator-forcenewpassword] .amplify-heading--3 {
  display: none;
}

@media screen and (min-width: 960px) {
  [data-amplify-authenticator] [data-amplify-router] {
    width: 100%;
  }
}
